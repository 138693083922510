// This file is included before commons.js so keep it barebone (no JQuery available etc.)
import * as Sentry from '@sentry/browser';

const environment = (document.getElementById("error-logging-environment") as HTMLInputElement).value;

if(!environment || environment !== "LokalUdvikling") { // LokalUdvikling i EnvironmentEnum
    Sentry.init({
        dsn: (document.getElementById("error-logging-secret") as HTMLInputElement).value,
        environment: environment,
    });

    const userIdElement = (document.getElementById("error-logging-user-id") as HTMLInputElement);

    if(userIdElement !== null) {
        Sentry.configureScope((scope: any) => {
            scope.setUser({
                "id": (document.getElementById("error-logging-user-id") as HTMLInputElement).value,
                "email": (document.getElementById("error-logging-user-email") as HTMLInputElement).value,
                "name": (document.getElementById("error-logging-user-name") as HTMLInputElement).value,
            });
        });
    }
}
else {
    console.log("Ignore Sentry because of environment: ", environment);
}
